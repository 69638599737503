<template>
  <div>
    <div
      v-if="
        typeof error_messages === 'object' &&
        Object.keys(error_messages).length > 0
      "
      class="alert alert-danger"
    >
      <ul>
        <template v-for="(error, k) in error_messages">
          <span v-bind:key="k">
            <li v-for="(message, i) in error" v-bind:key="i">{{ message }}</li>
          </span>
        </template>
      </ul>
    </div>

    <v-stepper v-model="step" alt-labels>
      <v-stepper-header class="p-3">
        <h1>Маршрутна карта</h1>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="mb-12" color="grey lighten-1">
            <v-row>
              <v-col cols="12">
                <div class="form-group">
                  <label for="">
                    <span> Мениджър:</span>
                  </label>
                  <div class="field-wrap">
                    <vu-select
                      :options="users"
                      label="name"
                      v-model="routemap.productManagerId"
                      :value="routemap.productManagerId"
                      :reduce="(user) => user.id"
                    ></vu-select>
                    <div v-if="$v.routemap.$error">
                      <div
                        class="text-danger"
                        v-if="!$v.routemap.productManagerId.required"
                      >
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>

              <v-col cols="6">
                <div class="form-group">
                  <label for="">
                    <span> Продукти:</span>
                  </label>
                  <div class="field-wrap">
                    <vu-select
                      append-to-body
                      :options="products"
                      label="name"
                      v-model="routemap.productId"
                      :value="routemap.productId"
                      :reduce="(products) => products.id"
                      @input="getProductInfo"
                      :disabled="$route.params.id !== undefined"
                    ></vu-select>
                    <div v-if="$v.routemap.$error">
                      <div
                        class="text-danger"
                        v-if="!$v.routemap.productId.required"
                      >
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>

              <v-col cols="6">
                <div class="form-group">
                  <label for="">
                    <span> Рецепти:</span>
                  </label>
                  <div class="field-wrap">
                    <vu-select
                      :options="formulas"
                      label="name"
                      v-model="routemap.productFormulaId"
                      :reduce="(formulas) => formulas.id"
                      @input="changeRecipe"
                      :disabled="$route.params.id !== undefined"
                    ></vu-select>
                    <div v-if="$v.routemap.$error">
                      <div
                        class="text-danger"
                        v-if="!$v.routemap.productFormulaId.required"
                      >
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>

              <v-col cols="6">
                <div class="form-group">
                  <label for="">
                    <span> Партида:</span>
                  </label>
                  <div class="field-wrap">
                    <vu-select
                      :options="batches"
                      label="batch"
                      v-model="routemap.productBatch"
                      :reduce="(batche) => batche.id"
                      @input="loadRecipe"
                    ></vu-select>
                    <div v-if="$v.routemap.$error">
                      <div
                        class="text-danger"
                        v-if="!$v.routemap.productFormulaId.required"
                      >
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>

              <v-col cols="6">
                <div class="form-group">
                  <label for="">
                    <span> Зали:</span>
                  </label>
                  <div class="field-wrap">
                    <vu-select
                      :options="halls"
                      label="name"
                      v-model="routemap.halls"
                      :value="routemap.halls"
                      multiple
                    ></vu-select>
                    <div v-if="$v.routemap.$error">
                      <div
                        class="text-danger"
                        v-if="!$v.routemap.halls.required"
                      >
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>

              <v-col cols="6">
                <div class="form-group">
                  <label for="">
                    <span> Бройка:</span>
                  </label>
                  <div class="field-wrap">
                    <input
                      v-model="routemap.quantity"
                      type="text"
                      class="form-control"
                      @change="loadRecipe"
                    />
                    <div v-if="$v.routemap.quantity.$error">
                      <div
                        class="text-danger"
                        v-if="!$v.routemap.quantity.required"
                      >
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                      <div
                        class="text-danger"
                        v-if="!$v.routemap.quantity.maxValue"
                      >
                        Недостатъчно количество на суровина
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>

              <v-col cols="6">
                <div class="form-group">
                  <label for="">
                    <span> Дата и час на зареждане:</span>
                  </label>
                  <div class="field-wrap">
                    <datetime
                      zone="local"
                      value-zone="local"
                      type="datetime"
                      v-model="routemap.materialDate"
                      input-class="form-control"
                      format="yyyy-MM-dd HH:mm"
                    ></datetime>
                    <div v-if="$v.routemap.$error">
                      <div
                        class="text-danger"
                        v-if="!$v.routemap.materialDate.required"
                      >
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>

            <div v-if="routemap.recipe.length > 0" class="col-12">
              <hr />
              <h4>Материално разходен норматив</h4>
              <v-row>
                <v-col cols="4"> &nbsp; </v-col>
                <v-col cols="4"> Количесто бр. </v-col>
              </v-row>
              <v-row v-for="(cost, index) in routemap.recipe" :key="cost.id">
                <template v-if="cost.typeableType === 'material'">
                  <v-row
                    v-for="(batch, batchIndex) in cost.batches"
                    :key="batch.batchId"
                    class="col-lg-12"
                  >
                    <v-col cols="4">
                      <strong>{{ cost.typeName }} {{ cost.name }} </strong>
                      <br />
                    </v-col>
                    <v-col cols="3">
                      <vu-select
                        :options="
                          materialBatchesOptions(cost.typeableId, batch.batchId)
                        "
                        label="name"
                        :value="batch.batchId"
                        :reduce="(batch) => batch.id"
                        @input="
                          changeMaterialBatch(
                            batch,
                            cost.typeableId,
                            batch.batchId,
                            $event
                          )
                        "
                      ></vu-select>
                    </v-col>
                    <v-col cols="1">
                      <input
                        v-model="batch.quantity"
                        type="text"
                        class="form-control"
                      />
                    </v-col>
                    <v-col cols="1">
                      <v-tooltip top v-if="batchIndex === 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ml-2 mt-2"
                            fab
                            dark
                            x-small
                            color="#3dbdad"
                            v-bind="attrs"
                            v-on="on"
                            @click="addMaterialBatch(cost.id, batch.id)"
                          >
                            <v-icon> fas fa-plus </v-icon>
                          </v-btn>
                        </template>
                        <span>Добави</span>
                      </v-tooltip>
                      <v-tooltip top v-if="cost.batches.length > 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ml-2 mt-2"
                            fab
                            dark
                            x-small
                            color="#f5222d"
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteMaterialBatch(cost.id, batch.batchId)"
                          >
                            <v-icon> fas fa-trash-alt </v-icon>
                          </v-btn>
                        </template>
                        <span>Изтрий</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  <v-col cols="4">
                    <strong>{{ cost.typeName }} {{ cost.name }} </strong>
                    <br />
                  </v-col>
                  <v-col cols="4">
                    <input
                      v-model="cost.quantity"
                      type="text"
                      class="form-control"
                    />
                  </v-col>
                </template>

                <v-col cols="4" v-if="index === 0">
                  <div class="row detail-labels">
                    <v-col>
                      <v-checkbox
                        v-model="cost.warehouseHasSample"
                        color="red"
                        hide-details
                      ></v-checkbox>
                      В склада за музейни мостри вече съществува мостра за този
                      партиден номер.
                    </v-col>
                  </div>
                </v-col>
              </v-row>
            </div>

            <v-btn
              @click="save()"
              :disabled="isSubmitDisabled"
              class="btn btn-success btn-elevate kt-login__btn-primary text-white"
              >{{ $t("BUTTON.SAVE") }}
            </v-btn>

            <v-progress-circular
              class="just-center"
              indeterminate
              color="primary"
              v-if="isLoadingRoutemaps"
            ></v-progress-circular>

            <br />
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import moment from "moment";

import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

import {
  GET_ROUTEMAP,
  SAVE_ROUTEMAP,
  UPDATE_ROUTEMAP,
} from "@/store/routemaps.module";

import {
  GET_ROUTEMAPS_OPERATIONS_DATA,
  SAVE_ROUTEMAPS_OPERATIONS,
} from "@/store/routemapsOperations.module";

import {
  GET_ROUTEMAPS_CONTROLS_DATA,
  SAVE_ROUTEMAPS_CONTROLS,
} from "@/store/routemapsControls.module";

import { GET_ACCEPTANCEACT_DETAIL_FOR_PRODUCT } from "@/store/acceptanceActs.module";

import { GET_PRODUCTFORMULAS } from "@/store/productFormulas.module";

import { GET_DATA } from "@/store/formselect.module";

import { GET_ALL_OPERATORS } from "@/store/operators.module";

import { GET_ALL_USERS } from "@/store/users.module";

import { mapGetters, mapState } from "vuex";
// Vue.use(VueFormGenerator)
import _ from "lodash";

import { required } from "vuelidate/lib/validators";
import { GET_CERTIFICATE_BATCHES } from "@/store/certificates.module";

export default {
  name: "createUpdate",

  components: {},
  computed: {
    ...mapGetters(["isLoadingRoutemaps"]),
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    user: function () {
      return JSON.parse(window.localStorage.getItem("userData"));
    },
    isEdit: function () {
      if (typeof this.$route.params.id !== "undefined") {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      users: [],
      error_messages: [],
      model: "routemaps",
      step: 1,
      tab: 0,
      isSubmitDisabled: false,
      routemap: {
        recipe: [],
        productManagerId: null,
        productId: null,
        productBatch: null,
        productFormulaId: null,
        halls: null,
        quantity: null,
        materialDate: null,
        materialBatches: [],
      },
      routemaps_operations: [],
      routemaps_controls: [],
      halls: [],
      batches: [],
      operators: [],
      products: [],
      formulas: [],
      new_operation: {
        routeMapId: 0,
        operationId: 0,
      },
      new_control: {
        routeMapId: 0,
      },
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true,
      },
    };
  },
  validations: {
    routemap: {
      productManagerId: {
        required,
      },
      productId: {
        required,
      },
      productFormulaId: {
        required,
      },
      halls: {
        required,
      },
      quantity: {
        required,
        maxValue(value) {
          const vm = this;

          if (vm.routemap.productBatch > 0) {
            const selectedBatch = vm.getSelectedBatch();

            let formula = _.find(vm.formulas, [
              "id",
              vm.routemap.productFormulaId,
            ]);

            return (
              selectedBatch &&
              value <=
                selectedBatch.residualQuantity / formula.productPrimaryQIB
            );
          } else {
            return false;
          }
        },
      },
      materialDate: {
        required,
      },
    },
  },
  filters: {
    showDaNe: function (value) {
      if (value == true) {
        return "ДА";
      } else {
        return "НЕ";
      }
    },
  },
  mounted() {
    let vm = this;

    _.delay(function () {
      var current_page = "Добави";
      if (typeof vm.$route.params.id !== "undefined") {
        current_page = "Промени";
      }
      vm.$store.dispatch(SET_BREADCRUMB, [
        { title: "Маршрутна карта", route: "/routemaps" },
        { title: current_page },
      ]);
    }, 300);

    this.getOperators();
    this.getUsers();
    // this.getLabourers();

    if (typeof this.$route.params.id !== "undefined") {
      // this.step = 2;

      this.new_operation.routeMapId = this.$route.params.id;
      this.new_control.routeMapId = this.$route.params.id;
      this.getData();
      this.getRoutemapsControls();
      this.getRoutemapsOperations();
    } else {
      this.getSelects();
    }
  },
  methods: {
    changeMaterialBatch: function (batch, materialId, oldBatchId, newBatchId) {
      batch.batchId = newBatchId;
      this.changeBatchSelectedProperty(materialId, oldBatchId, false);
      this.changeBatchSelectedProperty(materialId, newBatchId, true);
    },
    changeBatchSelectedProperty: function (materialId, batchId, status) {
      _.each(this.routemap.materialBatches[materialId], (batch) => {
        if (batch.id == batchId) {
          batch.selected = status;
        }
      });
    },
    materialBatchesOptions: function (materialId, batchId) {
      let options = _.filter(
        this.routemap.materialBatches[materialId],
        (batch) => batch.id === batchId || !batch.selected
      );

      _.each(options, (option) => {
        if (option.id === batchId) {
          option.selected = true;
        }
      });

      return options;
    },
    deleteMaterialBatch: function (costId, batchId) {
      let recipe = _.find(this.routemap.recipe, (cost) => cost.id === costId);

      this.changeBatchSelectedProperty(recipe.typeableId, batchId, false);

      recipe.batches = _.filter(
        recipe.batches,
        (batch) => batch.batchId !== batchId
      );
    },
    addMaterialBatch: function (costId) {
      var recipe = _.find(this.routemap.recipe, (cost) => cost.id === costId);

      recipe.batches.push({
        quantity: 0,
        batchId: 0,
      });
    },
    changeRecipe: function () {
      let vm = this;
      const formulaId = vm.routemap.productFormulaId;
      const formula = _.find(vm.formulas, function (f) {
        return f.id === formulaId;
      });

      let rawProductInfo = formula.details[0];

      vm.loadProductBatches(rawProductInfo.typeableId);
      vm.loadMaterialBatches(formula);
      this.loadRecipe();
    },
    batchIsUsed: function () {
      const selectedBatch = this.getSelectedBatch();

      if (!selectedBatch) {
        return false;
      }

      return selectedBatch.batchIsUsed;
    },
    getSelectedBatch: function () {
      const vm = this;

      return _.find(
        vm.batches,
        (batch) => batch.id === vm.routemap.productBatch
      );
    },
    getProductInfo: function () {
      const vm = this;

      vm.getFormulas(true);
    },
    loadMaterialBatches: function (formula) {
      let vm = this;
      const formulaMaterialIds = _.filter(
        formula.details,
        (m) => m.typeableType === "material"
      )
        .map((m) => m.typeableId)
        .join(",");

      vm.$store
        .dispatch(GET_CERTIFICATE_BATCHES, formulaMaterialIds)
        .then((data) => {
          vm.$nextTick(function () {
            vm.routemap.materialBatches = data.data;
          });
        })
        .catch((response) => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300,
          });

          vm.$notification["success"]({
            message: response.message,
            description: response.message,
          });
        });
    },
    loadProductBatches: function (productId, changeEvent = true) {
      const vm = this;

      if (changeEvent) {
        vm.routemap.productBatch = null;
      }

      vm.batches = [];

      vm.$store
        .dispatch(GET_ACCEPTANCEACT_DETAIL_FOR_PRODUCT, {
          productId: productId,
          routeMapId: vm.$route.params.id,
        })
        .then((data) => {
          vm.$nextTick(function () {
            vm.batches = data.data;
          });
        })
        .catch((response) => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300,
          });

          vm.$notification["success"]({
            message: response.message,
            description: response.message,
          });
        });
    },
    loadRecipe: function () {
      const vm = this;

      if (vm.routemap.quantity) {
        vm.$v.routemap.quantity.$touch();

        const formulaId = vm.routemap.productFormulaId;
        vm.routemap.recipe = [];

        if (formulaId > 0) {
          const formula = _.find(vm.formulas, function (f) {
            return f.id === formulaId;
          });

          vm.routemap.recipe = formula.details.map((detail) => {
            let necessaryProductQuantity = 0;
            if (detail.typeableType !== "product") {
              let sample = 1;

              if (
                detail.name === "Tamper-evident" &&
                detail.typeName === "Стикер"
              ) {
                sample = 0;
              }

              necessaryProductQuantity = vm.routemap.quantity * detail.quantity;
              necessaryProductQuantity = Math.ceil(
                necessaryProductQuantity + necessaryProductQuantity * 0.02
              );
              necessaryProductQuantity += sample;
            } else {
              detail.warehouseHasSample = vm.batchIsUsed() ? 1 : 0;

              const selectedBatch = vm.getSelectedBatch();

              necessaryProductQuantity = selectedBatch.residualQuantity;
            }

            if (detail.typeableType !== "material") {
              return {
                ...detail,
                quantity: necessaryProductQuantity,
              };
            }

            let materialId = detail.typeableId;
            let hasEnoughQuantity = true;

            let result = {
              ...detail,
              batches: [],
            };

            let quantity = 0;

            if (vm.routemap.materialBatches[materialId]) {
              for (let materialBatch of vm.routemap.materialBatches[
                materialId
              ]) {
                materialBatch.selected = true;

                let availableMaterialQuantity =
                  materialBatch.quantity - materialBatch.used_quantity;

                if (availableMaterialQuantity >= necessaryProductQuantity) {
                  hasEnoughQuantity = true;
                  quantity = necessaryProductQuantity;
                } else {
                  hasEnoughQuantity = false;
                  quantity = availableMaterialQuantity;
                  necessaryProductQuantity = Math.abs(
                    availableMaterialQuantity - necessaryProductQuantity
                  );
                }

                result.batches.push({
                  quantity,
                  batchId: materialBatch.id,
                });

                if (hasEnoughQuantity) {
                  break;
                }
              }
            } else {
              result.batches.push({
                quantity: 0,
                batchId: 0,
              });
            }

            return result;
          });
        } else if (vm.routemap.recipe.length > 0) {
          vm.routemap.recipe = [];
        }
      }
    },
    getUsers: function () {
      let vm = this;
      this.error_messages = [];
      this.$store
        .dispatch(
          GET_ALL_USERS,
          "?roles[]=Admin&roles[]=Qualified&roles[]=Manager"
        )
        .then((data) => {
          vm.$nextTick(function () {
            vm.users = data.data;
          });
        })
        .catch((response) => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300,
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!",
            });
          }
        });
    },
    save() {
      this.$v.$touch();

      if (this.$v.routemap.$error) {
        return;
      }

      let vm = this;
      var action =
        typeof this.$route.params.id !== "undefined"
          ? UPDATE_ROUTEMAP
          : SAVE_ROUTEMAP;

      this.error_messages = [];

      let data = {
        ...vm.routemap,
        materialDate: moment(vm.routemap.materialDate).format(
          "YYYY-MM-DD HH:mm"
        ),
      };

      if (vm.isSubmitDisabled == false) {
        vm.$store
          .dispatch(action, { payload: data })
          .then((data) => {
            vm.$notification["success"]({
              message: data.status,
              description: data.message,
            });

            vm.$nextTick(function () {
              vm.routemap = data.data;
              vm.$router.push({
                name: "dashboard",
              });
            });
          })
          .catch((response) => {
            vm.error_messages = response.data.errors;
            vm.$scrollTo("html", 420, {
              offset: -300,
            });

            vm.$notification["success"]({
              message: response.message,
              description: response.message,
            });
          });
      }
    },
    saveOperation: function () {
      let vm = this;
      var action = SAVE_ROUTEMAPS_OPERATIONS;

      this.error_messages = [];
      vm.new_operation.date = moment(vm.new_operation.date).format(
        "YYYY-MM-DD"
      );
      vm.$store
        .dispatch(action, { payload: vm.new_operation })
        .then((data) => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message,
          });

          vm.new_operation = {
            routeMapId: this.$route.params.id,
          };

          vm.$nextTick(function () {
            vm.getRoutemapsOperations();
          });
        })
        .catch((response) => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300,
          });

          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message,
            });
            vm.$router.push({ name: "routemapsList" });
          }
        });
    },
    saveControl: function () {
      let vm = this;
      var action = SAVE_ROUTEMAPS_CONTROLS;

      vm.new_control.controlAt = moment(vm.new_control.controlAt).format(
        "YYYY-MM-DD H:mm:ss"
      );
      this.error_messages = [];
      vm.$store
        .dispatch(action, { payload: vm.new_control })
        .then((data) => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message,
          });

          vm.new_control = {
            routeMapId: this.$route.params.id,
          };

          vm.$nextTick(function () {
            vm.getRoutemapsControls();
          });
        })
        .catch((response) => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300,
          });

          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message,
            });
            vm.$router.push({ name: "routemapsList" });
          }
        });
    },
    getSelects: function () {
      let vm = this;
      let payload = {
        halls: {
          fields: ["id", "name"],
          search: "",
        },
        products: {
          fields: ["id", "name"],
          search: "",
        },
      };
      this.error_messages = [];
      return vm.$store
        .dispatch(GET_DATA, payload)
        .then((data) => {
          vm.$nextTick(function () {
            vm.halls = data.data.halls;
            vm.products = data.data.products;

            if (vm.routemap.productIsArchived) {
              vm.products.push({
                id: vm.routemap.productId,
                name: vm.routemap.productName + " (Архивиран)",
              });
            }
          });
        })
        .catch((response) => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300,
          });
        });
    },
    getRoutemapsOperations: function () {
      let vm = this;

      let id = vm.$route.params.id;

      let payload = {
        columns: [
          {
            name: "routeMapId",
            search: id,
          },
          {
            name: "id",
            search: "",
          },
          {
            name: "operationId",
            search: "",
          },
          {
            name: "operation.name",
            search: "",
          },
          {
            name: "operation.product.name",
            search: "",
          },
          {
            name: "operatorId",
            search: "",
          },
          {
            name: "operator.firstName",
            search: "",
          },
          {
            name: "operator.lastName",
            search: "",
          },
          {
            name: "quantity",
            search: "",
          },
          {
            name: "scrap",
            search: "",
          },
          {
            name: "shift",
            search: "",
          },
          {
            name: "date",
            search: "",
          },
        ],
        search: "",
        rows: 500,
        page: 0,
        order: "id",
        orderType: "asc",
      };

      this.error_messages = [];
      vm.$store
        .dispatch(GET_ROUTEMAPS_OPERATIONS_DATA, payload)
        .then((data) => {
          vm.$nextTick(function () {
            vm.routemaps_operations = data.data.data;
          });
        })
        .catch((response) => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300,
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!",
            });
          }
        });
    },
    getRoutemapsControls: function () {
      let vm = this;

      let id = vm.$route.params.id;

      let payload = {
        columns: [
          {
            name: "routeMapId",
            search: id,
          },
          {
            name: "id",
            search: "",
          },
          {
            name: "package",
            search: "",
          },
          {
            name: "instructions",
            search: "",
          },
          {
            name: "batch",
            search: "",
          },
          {
            name: "date",
            search: "",
          },
          {
            name: "time",
            search: "",
          },
          {
            name: "controlAt",
            search: "",
          },
        ],
        search: "",
        rows: 500,
        page: 0,
        order: "id",
        orderType: "asc",
      };

      this.error_messages = [];
      vm.$store
        .dispatch(GET_ROUTEMAPS_CONTROLS_DATA, payload)
        .then((data) => {
          vm.$nextTick(function () {
            vm.routemaps_controls = data.data.data;
          });
        })
        .catch((response) => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300,
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!",
            });
          }
        });
    },
    getOperators: function () {
      let vm = this;
      this.error_messages = [];
      this.$store
        .dispatch(GET_ALL_OPERATORS)
        .then((data) => {
          vm.$nextTick(function () {
            vm.operators = data.data;
          });
        })
        .catch((response) => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300,
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!",
            });
          }
        });
    },
    getFormulas(clear = false) {
      let vm = this;
      this.error_messages = [];
      vm.formulas = [];
      vm.$store
        .dispatch(GET_PRODUCTFORMULAS, vm.routemap.productId)
        .then((data) => {
          vm.formulas = data.data;

          if (clear) {
            vm.routemap.productFormulaId = null;
          }
        })
        .catch(() => {
          vm.routemap.productFormulaId = null;
        });
    },
    getData: function () {
      let vm = this;
      this.error_messages = [];
      let id = this.$route.params.id;
      this.$store
        .dispatch(GET_ROUTEMAP, id)
        .then((data) => {
          vm.$nextTick(function () {
            vm.routemap = data.data;

            vm.loadProductBatches(vm.routemap.rawProductId, false);

            vm.routemap.materialDate = moment(vm.routemap.materialDate).format(
              "YYYY-MM-DDTHH:mm:ss.SSS"
            );

            vm.getFormulas();
            vm.getSelects();
          });
        })
        .catch((response) => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300,
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!",
            });
            vm.$router.push({ name: "routemapsList" });
          }
        });
    },
    handleValidation: function (isValid) {
      this.isSubmitDisabled = !isValid;
    },
  },
};
</script>
